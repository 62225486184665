
import { defineComponent, reactive, watch } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: ".pdf",
    },
    uniqueId: {
      type: String,
      default: "",
      required: true,
    },
    fileUrl: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const inputValue = reactive({
      file: null as File | Blob | null,
      fileName: "",
      fileSize: 0,
      fileUrl: "",
      fileExtension: "",
    });

    const handleDrag = (event: DragEvent) => {
      const files = event.dataTransfer?.files || [];
      setFile(files[0]);
    };

    const handleClick = (event: any) => {
      const inputEl = event.target as HTMLInputElement;
      const files = inputEl.files || [];
      setFile(files[0]);
      inputEl.value = "";
    };

    const triggerClick = () => {
      const id = `fileUpload${props.uniqueId}`;
      const inputFileEl: any = document.getElementById(id);
      inputFileEl.click();
    };

    const setFile = async (file: File) => {
      if (!file) {
        return false;
      }

      inputValue.file = file;
      inputValue.fileName = file.name;
      inputValue.fileSize = file.size;

      const setupFile: any = file;

      emit("update:value", setupFile);

      return true;
    };

    const setFileExtension = (fileName: string) => {
      const reverseFileName = fileName.split("").reverse();
      const tempName = [] as string[];

      for (let i = 0; i < reverseFileName.length; i++) {
        if (reverseFileName[i] === ".") break;
        tempName.push(reverseFileName[i]);
      }

      // update fileExtension
      inputValue.fileExtension = tempName.reverse().join("");
    };

    const setupFilePreview = () => {
      const fileUrl = props.fileUrl;
      if (fileUrl) return fileUrl;

      setFileExtension(inputValue.fileName);
    };

    watch(
      () => props.value,
      (newVal) => {
        if (newVal) setupFilePreview();
      }
    );

    const setFileNameFromUrl = (fileUrl: string) => {
      const reverseFileName = fileUrl.split("").reverse();
      const tempName = [] as string[];

      for (let i = 0; i < reverseFileName.length; i++) {
        if (reverseFileName[i] === "/") break;
        tempName.push(reverseFileName[i]);
      }

      // update fileName
      inputValue.fileName = tempName.reverse().join("");
    };

    watch(
      () => props.fileUrl,
      (newVal) => {
        console.log("newVal props.fileUrl ", newVal);

        if (newVal) {
          setFileNameFromUrl(newVal);
          setFileExtension(newVal);
        }
      }
    );

    const removeImage = () => {
      inputValue.file = null;
      inputValue.fileName = "";
      inputValue.fileSize = 0;

      emit("update:value", "");
    };

    return {
      inputValue,
      handleDrag,
      handleClick,
      triggerClick,
      removeImage,
    };
  },
});
